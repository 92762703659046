import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { legacyBreakpoints } from '../utils/media'
import ContentContainer from './ContentContainer'

const Content = styled.div`
  max-width: 500px;
  padding: 0 0 35px 30px;
  text-align: left;

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
    text-align: center;
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 25px;
  }

  h2 {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 18px;
  }
`

const Image = styled(GatsbyImage)`
  width: 33%;

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    width: 40%;
    text-align: center;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
  }

  @media screen and (max-width: 500px) {
    padding: 0 50px 20px 50px;
    width: 100%;
  }
`

const TipBox = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    flex-direction: column;
  }
`

const TipIntro = ({ title, description, image }) => (
  <TipBox>
    <Image
      image={getImage(image.localFile)}
      alt={`${title} resume tips for writing the perfect resume`}
    />
    <Content>
      <h2>{title}</h2>
      <p>{description?.description}</p>
    </Content>
  </TipBox>
)

const TipContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;
  background-color: white;
  box-shadow: var(--box-shadow-primary-small);
  border-radius: var(--border-radius);
  overflow: hidden;
  text-align: left;
`

const TipHeader = styled.div`
  width: 100%;
  padding: 20px;
  background-color: var(--color-background-primary);
  color: var(--color-typography-on-primary);
`

const TipBody = styled.div`
  width: 100%;
  padding: 30px;

  h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
  }
`

const Tip = ({ title, content, subtitle, index }) => (
  <TipContainer>
    <TipHeader>
      {index + 1} - {title}
    </TipHeader>
    <TipBody>
      <h2>{subtitle}</h2>
      <p>{content}</p>
    </TipBody>
  </TipContainer>
)

const PageSectionResumeTips = ({ section: { title, description, image, tips } }) => (
  <ContentContainer $omitTopPadding>
    {title && description && image && (
      <TipIntro
        title={title}
        description={description}
        image={image}
      />
    )}
    {tips
      .filter((tip) => tip.title)
      .map((tip, index) => (
        <Tip
          index={index}
          key={tip.id}
          title={tip.title}
          subtitle={tip.subtitle.subtitle}
          content={tip.content.content}
        />
      ))}
  </ContentContainer>
)

export default PageSectionResumeTips
